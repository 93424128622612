@import 'styles/common';

.map-fb-callback {
  position: absolute;
  border-radius: 6px;
  bottom: 28px;
  right: 8px;
  background: #FFFFFF;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  z-index: 999;
  height: 36px;

  img {
    height: 12px;
    padding: 12px;
    box-sizing: unset;
  }
}
