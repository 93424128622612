
 .hrr-tooltip-content {
    animation: .3s cubic-bezier(.17,.67,.68,1.3) tooltip-pop;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-around;
  }

 .hrr-tooltip {
   transition: opacity .3s ease-in;
   box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 7px 14px rgba(0, 0, 0, 0.13);
   border-radius: 6px;
 }

.hrr-tooltip-title {
  font-family: 'Facebook Reader';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #232323;
  flex: 0 1 auto;
  padding: 4px 0px;
}

.hrr-tooltip-title-second {
  color: #9CA8B1;
  display: inline-block;
}


 .hrr-tooltip-item {
    font-family: 'Facebook Reader';
    font-size: 12px;
    line-height: 14px;
    display: flex;
    flex: 0 1 auto;
    align-items: flex-start;
    padding: 2px 0px;
    width: 100%;
    justify-content: space-between;

    .hrr-tooltip-label {
      color: #9CA8B1;
    }

    .hrr-tooltip-label-highlight {
      color: #677B8C;
      font-weight: bold;
      line-height: 20px;
    }

    .hrr-tooltip-label.stay-at-home {
      font-style: italic;
    }

    .hrr-tooltip-value {
        background: #677B8C;
        color: white;
        border-radius: 4px;
        align-self: flex-end;
        padding: 4px 6px;
        font-style: normal;
        font-weight: bold;
        line-height: 12px;
        // height: 20px;
        margin-left: 12px;
        text-align: right;
        min-width: 32px;
    }

    .hrr-tooltip-value-normal {
        background: white;
        color: #677B8C;
        border-radius: 2px;
        align-self: flex-end;
        padding: 1px 6px;
        font-style: normal;
        line-height: 12px;
        margin-left: 12px;
        text-align: right;
        min-width: 32px;
    }
}
