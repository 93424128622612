@import 'styles/common';

.layer-select,
.layer-select form {
  display: inline-block;
}
.layer-select select {
  font-family: 'Facebook Reader';
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: inline-block;
  height: 28px;
  background: #f5f5f6;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  margin: 0 0px;
  padding: 0 12px;
  padding-right: 35px;
  /* attempt to custom-style select element */
  background-image: url(../../../assets/svg/ic-arrow-down.svg);
  background-position: calc(100% - 1.3em) center;
  background-repeat: no-repeat;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

@media (max-width: $mobile-break) {
  .layer-select,
  .layer-select form {
    display: block;
    width: 100%;
    height: 36px;

    select {
      width: 100%;
      font-size: 11px;
      margin-bottom: 8px;
    }
  }
}
