.chart-info-legend {
  display: flex;
  flex-direction: row;

  .chart-info-legend-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chart-info-legend-line {
    display: inline-block;
    width: 12px;
    height: 0px;
    border-top: 1px solid black;
    margin: 0 4px;

    &.other-line {
      border-top: 1px solid #C4C4C4;
    }

    &.average-line {
      border-top: 1px dashed #232323;
    }
  }
}
