@import 'styles/common';

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Facebook Reader';
  font-size: 11px;
  line-height: 18px;
  flex: 0 1 auto;
  width: 100%;

  .chart-info-legend {
    display: flex;
    flex-direction: row;

    .chart-info-legend-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .chart-info-legend-line {
      display: inline-block;
      width: 12px;
      height: 0px;
      border-top: 1px solid black;
      margin: 0 4px;

      &.other-line {
        border-top: 1px solid #C4C4C4;
      }

      &.average-line {
        border-top: 1px dashed #232323;
      }
    }
  }
}

.chart-tooltip {
  bottom: 10px;
  padding: 4px 8px;
  width: 150px;
  display: flex;
  flex-direction: column;
  background: #344854;
  border-radius: 6px;

  .region {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 2px;
  }

  .date {
    margin: 4px;
    font-family: Facebook Reader;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #FFFFFF;
  }

  .entry {
    display: flex;
    flex-direction: row;
    font-family: Facebook Reader;
    font-style: normal;
    font-weight: normal;

    .legend {
      font-family: Facebook Reader;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 14px;
      color: #FFFFFF;
      opacity: 0.6;
    }
    .value {
      margin: 4px;
      height: 20px;
      background: #677B8C;
      border-radius: 4px;
      font-family: Facebook Reader;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: right;
      white-space: nowrap;
      color: #FFFFFF;
    }
  }
}

.chart {
  border-radius: 6px;
  padding: 8px;
  margin: 8px 0px;
  background: #ffffff;
  font-size: 12px;
  line-height: 20px;
  color: #232323;
  font-weight: 500px;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  z-index: 100;

  .plot {
    height: 110px;

    .recharts-wrapper {
      margin: 0px 0px -30px -10px;
    }

    .recharts-label {
      font-size: 12px;
      line-height: 12px;
      color: rgba(35, 35, 35, 0.85);
      padding: 4px 0px;
    }
  }

  .layer-select,
  .layer-select form {
    display: inline-block;
  }
  .layer-select button {
    font-family: "Facebook Reader";
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    height: 28px;
    background: #f5f5f6;
    border: none;
    border-radius: 6px;
    margin: 0 0px;
    padding: 0 12px;
    text-align: left;
    cursor: pointer;
  }
  .layer-select select {
    font-family: "Facebook Reader";
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: inline-block;
    height: 28px;
    background: #f5f5f6;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    margin: 0 0px;
    padding: 0 12px;
    padding-right: 35px;
    /* attempt to custom-style select element */
    background-position: calc(100% - 1.3em) center;
    background-repeat: no-repeat;
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  .source-date {
    font-family: Facebook Reader;
    font-style: italic;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    text-align: right;
    color: #979797;
  }

}

@media (max-width: $mid-break) {
  .chart{
    height: 325px;

    .header-row {
      flex-direction: column;
    }

    .layer-select,
    .layer-select form {
      display: block;
      width: 100%;

      select {
        width: 100%;
        font-size: 11px;
        margin-bottom: 8px;
      }
    }

    .plot {
      width: unset;
      height: 180px;
      margin: 0px 0px -50px -20px;
      padding-left: 4px;
    }
  }
}
