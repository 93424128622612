@import 'styles/common';

.code-generator {
  width: 25%;
  margin-right: 32px;

  .code-generator-options {
    div {
      display: inline-block;
      width: 100%;
    }

    p {
      font-size: 10px;
      line-height: 20px;
      font-weight: 500;
      color: #c4c4c4;
      text-transform: uppercase;
    }

    .geocoder {
      font-family: 'Roboto';

      p {
        margin-top: 20px;
      }

      input {
        height: 36px;
        width: 100%;
        right: 8px;
        background-color: white;
        background-repeat: no-repeat;
        background-image: url('../../../assets/svg/icon_search.svg');
        background-size: 16px;
        border: none;
        padding: 16;
        text-indent: 6px;
        font-size: 13px;
        font-family: 'Facebook Reader';
        line-height: 36px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        background-position: 6px center;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        border-radius: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      ul {
        width: 100%;
        background: white;
        border-radius: 6px;
        position: absolute;
        // top: 36px;
        right: 0;
        // margin-top: 10px;
        list-style: none;
        margin: 0;
        padding: 0;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);

        li {
          height: 36px;
          border-top: 1px solid #f1f1f1;
          margin: 4px;

          &:first-child {
            border: none;
          }
          a {
            display: list-item;
            font-size: 12px;
            line-height: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-radius: 6px;
            padding: 8px;

            &.strong {
              background-color: #f5f5f6;
            }
          }
          cursor: pointer;

          &:hover {
            a {
              background-color: #f5f5f6;
            }
          }
        }
      }
    }

    .code-generate-button {
      button {
        justify-content: center;
        align-items: center;
        font-family: 'Facebook Reader';
        background-color: #677b8c;
        border-radius: 6px;
        height: 36px;
        width: 100%;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        cursor: pointer;
        border: 0;
        margin-top: 32px;

        img {
          width: 16px;
          height: 16px;
          padding-right: 8px;
          vertical-align: middle;
        }
      }
    }

    .size-select {
      select {
        width: 100%;
        height: 36px;
        font-family: 'Facebook Reader';
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        display: inline-block;
        background: #f5f5f6;
        border: none;
        cursor: pointer;
        border-radius: 6px;
        padding: 0 12px;
        /* attempt to custom-style select element */
        background-image: url('../../../assets/svg/ic-arrow-down.svg');
        background-position: calc(100% - 1.3em) center;
        background-repeat: no-repeat;
        border: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
      }
    }
  }
}

.code-snippet {
  background: #677b8c;
  border-radius: 4px;
  height: 86px;
  margin-top: 20px;
  position: relative;

  pre {
    font-family: 'Menlo', sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    padding: 12px;
    white-space: normal;
  }

}

// .iframe-map {
//     position: relative;
//     padding-bottom: 76.25%;
//     margin-top: 20px;
//     height: 0;
//     overflow: hidden;
//     margin-left: 25%;

//     iframe {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         overflow: hidden;
  //     }
// }

@media (max-width: $mobile-break) {

        .code-generator {
          .code-generator-options {
            padding: 0;

            /* overrides to reset absolute position to a normal in-the-flow element */
            border-radius: unset;
            box-shadow: none;
            max-width: none;
            z-index: auto;
            position: static;

            display: block;

            div {
              width: 100%;
            }

            .geocoder {
              padding: unset;

              ul {
                width: 100% !important;
                top: 0 !important;
                z-index: 99999 !important;
              }
            }

            .geocoder,
            .size-select,
            .code-generate-button {
              display: block;
              text-align: left;
              padding: unset;
            }

            .geocoder input,
            .size-select select {
              display: block;
              width: 100% !important;
              margin-top: 10px;
            }

            .code-generate-button button {
              width: 100%;
              margin-top: 10px;
            }

            .size-select select {
              margin-right: 0 !important;
              margin-left: 0 !important;
            }

            .code-generate-button {
              button {
                width: 100% !important;
                text-align: center !important;
              }
            }

            .rotate {
              -webkit-transform: rotate(-180deg);
              -moz-transform: rotate(-180deg);
              -o-transform: rotate(-180deg);
              -ms-transform: rotate(-180deg);
              transform: rotate(-180deg);
            }
          }
        }

}
