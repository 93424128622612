@import 'styles/common';

.embed {
  .eyebrow {
    color: rgba(80, 94, 107, 0.7);
    text-transform: uppercase;
    letter-spacing: 3.6px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }

  @media (max-width: $mobile-break) {
    .eyebrow {
      margin-bottom: 20px !important;
    }
  }
}
