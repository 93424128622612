@import 'styles/common';

/* Basics */
.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder *,
.mapboxgl-ctrl-geocoder *:after,
.mapboxgl-ctrl-geocoder *:before {
  box-sizing: border-box;
}

.mapboxgl-ctrl-geocoder {
  font-size: 12px;
  line-height: 20px;
  font-family: "Facebook Reader";
  font-weight: 500;
  position: relative;
  background-color: #fff;
  width: 100%;
  max-width: 207px;
  width: 207px;
  z-index: 1;
  border-radius: 6px;
  transition: width .25s, min-width .25s;
}

.mapboxgl-ctrl-geocoder--input {
  font-size: 12px;
  line-height: 20px;
  font-family: "Facebook Reader";
  font-weight: 500;
  width: 100%;
  border: 0;
  background-color: transparent;
  margin: 0;
  height: 28px;
  color: #232323;
  padding: 6px 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 6px 35px;
}

.mapboxgl-ctrl-geocoder--input::-ms-clear {
  display: none; /* hide input clear button in IE */
}

.mapboxgl-ctrl-geocoder--input:focus {
  color: #404040; /* fallback */
  color: rgba(0, 0, 0, 0.75);
  outline: 0;
  box-shadow: none;
  outline: thin dotted\8;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  z-index: 2;
  position: absolute;
  right: 8px;
  top: 7px;
  display: none;
}

/* Collapsed */
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  width: 50px;
  min-width: 50px;
  transition: width .25s, min-width .25s;
}

/* Suggestions */
.mapboxgl-ctrl-geocoder .suggestions {
  background-color: #fff;
  border-radius: 6px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 2px 0;
  position: absolute;
  max-width: 238px;
  width: 238px;
  top: 110%; /* fallback */
  top: calc(100% + 6px);
  z-index: 1000;
  font-weight: 500;
  color: #232323;
  a {
    color: #232323;
  }
  font-size: 11px;
  line-height: 16px;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);

  li {
    margin: 2px 4px !important;
    display: block !important;
    // position: unset !important;
    // width: unset !important;
  }

  .geocoder-dropdown-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.mapboxgl-ctrl-bottom-left .suggestions,
.mapboxgl-ctrl-bottom-right .suggestions {
  top: auto;
  bottom: 100%;
}

.mapboxgl-ctrl-geocoder .suggestions > li > a {
  cursor: default;
  display: block;
  padding: 6px 12px;
  border-radius: 6px;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a,
.mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
  color: #979797;
  background-color: #f3f3f3;
  text-decoration: none;
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--suggestion-title,
.mapboxgl-ctrl-geocoder--suggestion-address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mapbox-gl-geocoder--error, .mapbox-gl-geocoder--no-results{
  // make it go away so we can overwrite the content
  visibility: hidden;
  width: 238px;
  height: 32px;
  position: relative;
}

.mapbox-gl-geocoder--error:after, .mapbox-gl-geocoder--no-results:after{
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    color: #979797;
    padding: 8px;
    font-style: italic;
    content: "No data available for this location";
  }

/* Icons */
.mapboxgl-ctrl-geocoder--icon {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  fill: #757575;
  top: 4px; // equal to margin of map control
}

.mapboxgl-ctrl-geocoder--icon-search {
  position: absolute;
  width: 22.16px; // dumbness for the scale of the svg from mapbox
  height: auto;
  fill: #677B8C;
}

.mapboxgl-ctrl-geocoder--button {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: #fff;
  line-height: 1;
  border-radius: 4px;
  top: 5px !important;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
}

.mapboxgl-ctrl-geocoder--icon-close {
  flex: 1 0 auto;
  fill: #677B8C;
}

.mapboxgl-ctrl-geocoder--button:hover .mapboxgl-ctrl-geocoder--icon-close {
  fill: #909090;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  width: 26px;
  height: 26px;
  margin-top: 5px;
  margin-right: 0px;
  -moz-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -webkit-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

/* Animation */
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Media queries*/
@media screen and (min-width: 640px) {

  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
    width: 36px;
    min-width: 36px;
  }

  .mapboxgl-ctrl-geocoder--icon-loading {
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-right: -5px;
  }



}
 @media (max-width: $mobile-break) {
  .mapboxgl-ctrl-geocoder {
    width: 100%;
    max-width: unset;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
    height: 100%;
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    right: -32px;
  }

  .mapboxgl-ctrl-geocoder--input {
    padding: 8px 12px;
    vertical-align: text-top;
  }

  .mapboxgl-ctrl-geocoder .suggestions {
    max-width: unset;
    width: 100%;
  }
 }
