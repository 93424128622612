@import 'styles/common';

.timeline {
  border-radius: 6px;
  padding: 8px;
  margin: 8px 0px;
  background: #ffffff;
  color: #000000;
  z-index: 100;
  font-family: Facebook Reader;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 13px;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);

  .plot {
    width: 480px;
    height: 90px;

    .recharts-label {
      font-size: 12px;
      line-height: 12px;
      color: #232323;
      opacity: 0.85;
      padding: 4px 0px;
    }

    .recharts-wrapper {
      margin: 0px 0px 0px 40px;
    }
  }

  .recharts-legend-wrapper {
    margin-left: 40px;
    bottom: 30px !important;

    .recharts-default-legend {
      display: flex;
      justify-content: space-around;
      margin: -20px 0 0 0;
    }
  }

  .recharts-legend-item-text {
    font-size: 11px;
    line-height: 20px;
    color: #000000;
  }
}

@media (max-width: $mid-break) {
  .timeline {
    width: unset;
    margin: 2px 0 8px;
    border-radius: 2px 2px 6px 6px;
    box-shadow: none;

    .plot {
      width: 100%;
      margin: 0;

      .recharts-wrapper {
        margin: 0px 0px 0px 25px;
      }
    }

    .recharts-legend-wrapper {
      .recharts-default-legend{
        justify-content: start;
      }
    }
  }
}
