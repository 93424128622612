@import 'styles/common';

.copy-button {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  position: absolute;
  right: 12px;
  bottom: 12px;

  button {
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: 'Facebook Reader';
    background-color: white;
    border-radius: 6px;
    height: 36px;
    text-align: right;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    cursor: pointer;
    padding: 8px 12px;

    img {
      flex: 0 1 auto;
      height: 16px;
      width: 16px;
      top: 2.08px;
      left: 12px;
      padding-right: 8px;
    }

    div {
      flex: 0 1 auto;
    }
  }
}

@media (max-width: $mobile-break) {
      .copy-button {
        position: unset !important;
        right: unset !important;
        padding-top: 10px !important;
        margin-bottom: 10px !important;
      }
    }
