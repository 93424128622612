@import 'styles/common';

.map-control-panel {
  position: absolute;
  top: 24px;
  left: 24px;
  border-radius: 6px;
  padding: 4px;
  background: #ffffff;
  font-size: 12px;
  line-height: 20px;
  color: #232323;
  font-weight: 500px;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  z-index: 999;

  .info-hovers {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    li {
      display: inline-block;
      position: relative;
      margin: 1px;
    }

    img {
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -8px; /* half the icon height */
      width: 16px;
      height: 16px;

      &.layer-select-info-icon {
        right: 0;
      }
    }
  }

  .layer-select select,
  button.county-info,
  button.hrr-info {
    font-family: "Facebook Reader";
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: inline-block;
    height: 28px;
    background: #f5f5f6;
    border: none;
    cursor: pointer;
    border-radius: 6px;
  }

  .layer-select,
  .layer-select form {
    /*display: inline-block;*/
  }
  .layer-select select {
    margin-right: 8px;
    padding: 0 24px 0 12px;
    min-width: 260px;
    /* attempt to custom-style select element */
    background-image: url(../../../assets/svg/ic-arrow-down.svg);
    background-position: calc(100% - 8px) center;
    background-repeat: no-repeat;
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  button.county-info.clicked,
  button.hrr-info.clicked {
    background: #e1e4e5;
  }
  button.county-info,
  button.hrr-info {
    position: relative;
    padding: 8px 32px 8px 12px;
  }

  button.county-info {
    margin-right: 2px;
    border-radius: 6px 2px 2px 6px;
  }

  button.hrr-info {
    border-radius: 2px 6px 6px 2px;
  }
}

@media (max-width: $mobile-break) {
  .map-control-panel {
    padding: 0;

    /* overrides to reset absolute position to a normal in-the-flow element */
    border-radius: unset;
    box-shadow: none;
    max-width: none;
    z-index: auto;
    position: static;

    .info-hovers {
      padding: 0 40px 0 12px;
      position: absolute;
      right: 0;
      left: 0;
      background: #ffffff;
      z-index: 1010;
      display: block;

      li {
        display: block;
        padding-bottom: 12px;
      }

      img.layer-select-info-icon /* for specificity */ {
        right: -36px;
        /* bigger hit target for mobile */
        padding: 12px 10px;
        margin-top: -20px; /* half the icon height plus padding = 8 + 12 */
      }
    }

    button.county-info,
    button.hrr-info {
      display: block;
      margin: 0 0 12px 0;
      width: 100%;
      text-align: left;
    }

    button.county-info {
      margin-bottom: 2px;
      border-radius: 6px 6px 2px 2px;
    }

    button.hrr-info {
      border-radius: 2px 2px 6px 6px;
    }

    .collapsing-header {
      border-top: 1px solid rgba(151, 151, 151, 0.2);
      font-family: "Facebook Reader";
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;
      padding: 14px 0;
      margin: 0 12px;
      text-align: left;

      img {
        position: absolute;
        right: 12px;
        margin-top: 5px;
        transition: transform 0.3s;
      }
    }

    .rotate {
      -webkit-transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }

    .layer-select,
    .layer-select form {
      display: unset;
      padding-right: unset;
    }
    .layer-select select {
      margin: 0;
      width: 100%;
      height: 36px;
    }
  }
}
