@import 'styles/common';

.add-layer-selector {
    display: inline-block;
    margin-left: 4px;

    display: flex;
    align-items: center;

    .layer-selected,
    .layer-select {
        display: flex;
        padding-right: 0;

        font-family: "Facebook Reader";
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        height: 28px;
        background: #f5f5f6;
        margin: 0 0px;
        transition: width 0.2s, border-radius 0.2s;
        overflow: hidden;
        position: relative;
    }
    .layer-select {
        width: 28px;
        border-radius: 18px;
    }

    .layer-select.selected {
        width: 100%;
        line-height: 28px;
        margin: 0 0px;
        border-radius: 6px;

        span {
            padding: 0 12px;
        }

        img.layer-select-remove-icon {
            cursor: pointer;
            position: initial;
            width: 24px;
            height: 24px;
            margin: 2px 2px 2px 0;
        }
    }

    .layer-select select {
        display: inline-block;
        height: 28px;
        border: none;
        cursor: pointer;
        margin: 0 0px;
        margin-left: 36px;
        width: 175px;
        /* attempt to custom-style select element */
        background-position: calc(100% - 8px) center;
        background-repeat: no-repeat;
        border: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
    }

    .layer-select {
        img.layer-select-add-icon {
            position: absolute;
            left: 6px;
            width: 16px;
            height: 16px;
        }
    }

    .layer-select.expanded {
        width: 100%;
        border-radius: 6px;
    }
}

@media (max-width: $mobile-break) {

    .add-layer-selector {
        width: 100%;
        margin: 0;
        span {
            width: 100%;
        }

        .layer-select {
            width: 100%;
            border-radius: 6px;
            height: 36px;
            align-items: center;
            display: flex;

            &.selected {
                display: flex;
            }

            select {
                padding-left: 12px;
                width: 100%;
            }

            img.layer-select-add-icon /* for specificity */ {
                margin-top: -20px; /* half the icon height plus padding = 8 + 12 */
                padding-left: 0;
            }

            img.layer-select-remove-icon {
                position: unset;
                margin: 2px 2px 2px 0;
                padding: unset;
            }
        }
    }
}
