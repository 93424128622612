.chart-tooltip {
  z-index: 1400;
  bottom: 10px;
  padding: 4px 8px;
  width: 150px;
  display: flex;
  flex-direction: column;
  background: #344854;
  opacity: 0.7;
  border-radius: 6px;

  .region {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 2px;
  }

  .date {
    margin: 4px;
    font-family: Facebook Reader;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #FFFFFF;
  }

  .entry {
    display: flex;
    flex-direction: row;
    font-family: Facebook Reader;
    font-style: normal;
    font-weight: normal;

    .legend {
      font-family: Facebook Reader;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 14px;
      color: #FFFFFF;
      opacity: 0.6;
    }
    .value {
      padding: 0 4px 0 4px;
      height: 20px;
      background: white;
      border-radius: 4px;
      font-family: Facebook Reader;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: right;
      white-space: nowrap;
      color: #475b6c;
      opacity: 1;
    }
  }
}

