@import 'styles/common';

.scatterplot {
  bottom: 24px;
  left: 24px;
  position: absolute;

  .scatterplot-cell:hover,
  .scater-plot-ref-area {
    cursor: pointer;
  }

  .scatterplot-chart,
  .scatterplot-sources {
    padding: 8px;
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    z-index:1001;
  }

  .scatterplot-chart {
    border-radius: 6px 6px 2px 2px;
    margin-bottom: 2px;

    &.scatterplot-hovered {
      .recharts-wrapper {
        cursor: pointer !important;
      }
    }
  }

  .scatterplot-sources {
    border-radius: 2px 2px 6px 6px;

    .legend-text {
      font-family: 'Facebook Reader';
      font-style: italic;
      font-size: 11px;
      line-height: 16px;
      color: #979797;
      padding-bottom: 8px;
      width: 100%;
      max-width: 280px;
    }

  }

}

@media (max-width: $mid-break) {
  .scatterplot {
    margin: 0 8px;
    border-radius: 6px 6px 2px 2px;

    /* overrides to reset absolute position to a normal in-the-flow element */
    box-shadow: none;
    max-width: none;
    z-index: auto;
    position: static;

    background-color: #FFFFFF;
  }
}

