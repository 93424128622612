/* Need to write scss here to override bootstrap's CSS, wonky to do with styled
 components and needed to use bootstrap to copy the other site's header. */
.navbar-light .navbar-nav .nav-link {
  color: #34495e;
  :hover {
    color: #34495e;
  }
}

.navbar-light .navbar-brand {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 5px 0;
  margin: auto;

  &:hover {
    color: #2962ff;
  }

  transition: color .6s ease;

  margin-right: 1rem;
}

nav.navbar.navbar-light {
  height: 70px;
  margin-bottom: 1rem;
  background: #fff;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,.11);
  font-size: 12.32px;
  padding: 0 1rem;
  font-family: lora,sans-serif;
  font-weight: 400;
  line-height: 1.25;
  text-rendering: optimizeLegibility;
}

button.navbar-toggler {
  border: 0;
  margin: 0;
  padding: 0;
  z-index: 1001;

  span {
    img {
      display: inline;
      width: 1rem;
    }
  }
}

@media (max-width: 991.98px) {
  nav.navbar.navbar-light {
    height: 50px;
  }

  .navbar-light .navbar-brand {
    font-size: 1rem;
    margin: auto;
  }
  
  .navbar-brand-mobile-wrapper {
    z-index: 800;
    position: absolute;
    left: 0;
    right: 0;
  }

  .navbar-collapse {
    z-index: 1031!important;
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    background-color: #fff;

    padding: 1rem 2rem;
  }
}
