div.line-chart-dom-tooltip {
  position: absolute;
  z-index: 200;

  pointer-events: none;

  transition-property: left, top;
  transition-duration: 300ms;
  transition-timing-function: ease;
  backdrop-filter: blur(2px);
}
